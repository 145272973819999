const {
  REACT_APP_ENV,
  REACT_APP_GAME_NAME,
  REACT_APP_BASE_URL,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_USER_POOL,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_AUTH_COOKIE_DOMAIN,
  REACT_APP_PROXY_URL,
} = process.env

type AuthConfig = {
  gameName: string
  redirectEndpoint: string
  authCookieDomain: string
  authUserPoolWebClientId: string
  authUserPoolId: string
  cognitoDomain: string
  proxyUrl: string
  env: string
}

const getConfig = () => getAuthConfig()

// Harcode Environment
const getAuthConfig = (): AuthConfig => ({
  env: REACT_APP_ENV || '',
  gameName: REACT_APP_GAME_NAME || '',
  redirectEndpoint: REACT_APP_BASE_URL || '',
  authCookieDomain: REACT_APP_AUTH_COOKIE_DOMAIN || '',
  authUserPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID ||
    '',
  authUserPoolId: REACT_APP_COGNITO_USER_POOL || '',
  cognitoDomain: REACT_APP_COGNITO_DOMAIN || '',
  proxyUrl: REACT_APP_PROXY_URL || ''
})

export const AUTH_CONFIG = getConfig()
