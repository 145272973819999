import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import {AuthProtected} from 'web-common-react-ts'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'

import {AUTH_CONFIG} from './services/authConfigService'
import AppTitle from './components/ui/AppTitle'
import {PacmanLoader} from "react-spinners";

const LoadableApp = Loadable({
  loader: () => import(/* webpackChunkName: "protected/app" */ './AppWrapper'),
  loading() {
    return <PacmanLoader>Loading...</PacmanLoader>
  }
})

ReactDOM.render(
  <>
    <AppTitle />

    <AuthProtected
      game={AUTH_CONFIG.gameName}
      environment={AUTH_CONFIG.env}
      apiEndpoint={AUTH_CONFIG.proxyUrl}
      cookieDomain={AUTH_CONFIG.authCookieDomain}
      redirectEndpoint={AUTH_CONFIG.redirectEndpoint}
      userPoolWebClientId={AUTH_CONFIG.authUserPoolWebClientId}
      userPoolId={AUTH_CONFIG.authUserPoolId}
      cognitoDomain={AUTH_CONFIG.cognitoDomain}
    >
      <LoadableApp />
    </AuthProtected>
  </>,
  document.getElementById('root')
)
